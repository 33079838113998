var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6580c87e8b682904d3561e5c0c2f5d7799befd3c"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENV = process.env.NEXT_PUBLIC_SENTRY_ENV || process.env.NODE_ENV;

const IS_PR_PREVIEW = process.env.NEXT_PUBLIC_IS_PR_PREVIEW === 'true';

if (SENTRY_ENV !== 'development' && !IS_PR_PREVIEW) {
  Sentry.init({
    dsn: SENTRY_DSN || 'https://00b88926986543baab4aa29082880890@o1027675.ingest.sentry.io/6290103',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    environment: SENTRY_ENV,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    // replaysSessionSampleRate: 0.1,
    replaysSessionSampleRate: 0.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [new Sentry.Replay()],

    ignoreErrors: [
      // https://basis-nm.sentry.io/issues/4639718003/?environment=production&project=6290103&referrer=issue-stream&statsPeriod=14d&stream_index=1
      // https://github.com/getsentry/sentry-javascript/issues/3440
      'Non-Error promise rejection captured with value',
      // 'Non-Error promise rejection captured with keys',
      // https://stackoverflow.com/a/50387233
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
  });
} else {
  console.info('info - Skipping Sentry setup in preview mode and/or in development');
}
